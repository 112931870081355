export const defaultScreenTimeInSeconds = 20;
export const defaultScreenTimeInMs = defaultScreenTimeInSeconds * 1000;

export const OVERLAY_UNACTIVE_TIMEOUT = 3000; // in milliseconds

export const ZOOM_SCREEN_DEFAULT_STAY_IN_DETAIL_TIME = 3; // in seconds
export const ZOOM_SCREEN_DEFAULT_SEQ_DELAY_TIME = 2; // in seconds

export const GAME_SCREEN_DEFAULT_RESULT_TIME = 4; // in seconds

export const GAME_FIND_DEFAULT_NUMBER_OF_PINS = 1;

export const GAME_DRAW_DEFAULT_COLOR = "#000000";
export const GAME_DRAW_DEFAULT_THICKNESS = 5;
export const GAME_DRAW_DEFAULT_IS_ERASING = false;
